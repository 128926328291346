import axios from 'axios'
import { errors } from './errors.js'

export function catchHTTPErrors({ endpoint, request, error }) {
  if (error.response) {
    // Response from the server with status code and data
    console.error(`Response: ${JSON.stringify(error)}`)
    if (error.response.data.code >= 400 && error.response.data.code < 500) {
      throw new errors.BusinessEntityError({ request, message: error.response.data.message })
    } else {
      throw new errors.UnexpectedEntityError({ request, message: error.response.data.message })
    }
  } else {
    // Error without a response from the server
    console.error('Error:', error.message)
    throw new Error(`Unable to persist data from ${endpoint}: ${error.message}.`)
  }
}

export default async function postApiData(endpoint, body, token) {
  try {
    const options = {
      url: process.env.REACT_APP_RUUTS_API_URL + endpoint,
      method: 'POST',
      data: body,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await axios.request(options)
    return response.data
  } catch (error) {
    catchHTTPErrors({ endpoint, request: body, error })
    return null
  }
}

export async function postApiDataWithPayloadResponse(endpoint, body, token) {
  const response = await postApiData(endpoint, body, token)
  return response.payload
}
