import { Box, Stack } from '@mui/material'
import DragDrop from './DragDrop'
import Manual from './mapeo/Manual'
import ManualMapeoHeader from './mapeo/ManualMapeoHeader'
import MapeoHeader from './mapeo/MapeoHeader'
import ShowFolders from './mapeo/ShowFolders'

const CategorizationPanel = ({
  handleDrawingType,
  center,
  handleCenter,
  etapa,
  handleEtapa,
  perimeter,
  handlePerimeter,
  paddocks,
  handlePaddocks,
  samplingAreas,
  handleSamplingAreas,
  monitoringSites,
  handleMonitoringSites,
  unassigned,
  handleUnassigned,
  unassignedPoints,
  handleUnassignedPoints,
  exclusionAreas,
  handleExclusionAreas,
  mapUpdate,
  handleMapUpdate,
  otherPolygons,
  handleOtherPolygons,
  otherSites,
  handleOtherSites,
  actualYearId,
  handleActualYearId,
  saved,
  handleSaved,
  fileType,
  handleFileType,
  includeSoilInfo,
  handleIncludeSoilInfo,
  includeProjectArea,
  handleIncludeProjectArea,
  handleDownload,
}) => {
  return (
    <Stack
      sx={{
        width: '100%',
      }}
    >
      {etapa === 'inicial' || etapa === 'inicialManual' || etapa === 'drawingStarted' ? (
        <MapeoHeader />
      ) : null}
      {etapa === 'inicial' ? <ManualMapeoHeader handleEtapa={handleEtapa} /> : null}
      <Box
        sx={{
          width: '100%',
        }}
      >
        {etapa === 'inicial' || etapa === 'fileUploaded' ? (
          <Box m={2}>
            <DragDrop
              etapa={etapa}
              exclusionAreas={exclusionAreas}
              handleCenter={handleCenter}
              handleEtapa={handleEtapa}
              handleExclusionAreas={handleExclusionAreas}
              handleMonitoringSites={handleMonitoringSites}
              handleOtherPolygons={handleOtherPolygons}
              handleOtherSites={handleOtherSites}
              handlePaddocks={handlePaddocks}
              handlePerimeter={handlePerimeter}
              handleSamplingAreas={handleSamplingAreas}
              handleUnassigned={handleUnassigned}
              handleUnassignedPoints={handleUnassignedPoints}
              monitoringSites={monitoringSites}
              otherPolygons={otherPolygons}
              otherSites={otherSites}
              paddocks={paddocks}
              perimeter={perimeter}
              samplingAreas={samplingAreas}
              unassigned={unassigned}
              unassignedPoints={unassignedPoints}
            />
          </Box>
        ) : null}
        {etapa === 'inicialManual' || etapa === 'drawingStarted' ? (
          <Manual
            center={center}
            etapa={etapa}
            handleDrawingType={handleDrawingType}
            handleEtapa={handleEtapa}
            handlePerimeter={handlePerimeter}
            perimeter={perimeter}
          />
        ) : null}
        {etapa === 'showingFoldersManual' ||
        etapa === 'showingFoldersArchivo' ||
        etapa === 'showingFoldersApi' ? (
          <ShowFolders
            actualYearId={actualYearId}
            center={center}
            etapa={etapa}
            exclusionAreas={exclusionAreas}
            fileType={fileType}
            handleActualYearId={handleActualYearId}
            handleDownload={handleDownload}
            handleEtapa={handleEtapa}
            handleExclusionAreas={handleExclusionAreas}
            handleFileType={handleFileType}
            handleIncludeSoilInfo={handleIncludeSoilInfo}
            handleMapUpdate={handleMapUpdate}
            handleMonitoringSites={handleMonitoringSites}
            handleOtherPolygons={handleOtherPolygons}
            handleOtherSites={handleOtherSites}
            handlePaddocks={handlePaddocks}
            handlePerimeter={handlePerimeter}
            handleSamplingAreas={handleSamplingAreas}
            handleSaved={handleSaved}
            handleUnassigned={handleUnassigned}
            handleUnassignedPoints={handleUnassignedPoints}
            includeSoilInfo={includeSoilInfo}
            includeProjectArea={includeProjectArea}
            handleIncludeProjectArea={handleIncludeProjectArea}
            mapUpdate={mapUpdate}
            monitoringSites={monitoringSites}
            otherPolygons={otherPolygons}
            otherSites={otherSites}
            paddocks={paddocks}
            perimeter={perimeter}
            samplingAreas={samplingAreas}
            saved={saved}
            unassigned={unassigned}
            unassignedPoints={unassignedPoints}
          />
        ) : null}
      </Box>
    </Stack>
  )
}

export default CategorizationPanel
