import axios from 'axios'
import { catchHTTPErrors } from './postApiData'
import { downloadFile } from '../../utils/downloadFile'

export const getApiFile = async (endpoint, query, token) => {
  try {
    const options = {
      url: process.env.REACT_APP_RUUTS_API_URL + endpoint,
      method: 'GET',
      params: query,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }

    const response = await axios.request(options)

    const contentType = response.headers['content-type']
    const contentDisposition = response.headers['content-disposition']
    const fileName = contentDisposition
      ? contentDisposition.split('filename=')[1].split(';')[0]
      : 'file'

    downloadFile({ fileName, content: response.data, mimeType: contentType })
  } catch (error) {
    catchHTTPErrors({ endpoint, request: query, error })
  }
}
