import endpoints from './endpoints'
import { getApiFile } from './getApiFile'
import postApiData from './postApiData'

async function generateByYear({ farmId, year, token }) {
  try {
    const metrics = await postApiData(
      `${endpoints.metricsProvider}/all`,
      {
        farmId,
        year,
      },
      token,
    )
    return metrics
  } catch (error) {
    console.error(error)
    throw new Error('Unable to generate metrics')
  }
}
async function getZipByYear({ farmId, year, token }) {
  try {
    const metrics = await getApiFile(
      `${endpoints.metricsProvider}/zip`,
      {
        farmId,
        year,
      },
      token,
    )
    return metrics
  } catch (error) {
    console.error(error)
    throw new Error('Error al descargar el archivo:', error)
  }
}

export const metricsProviderService = {
  generateByYear,
  getZipByYear,
}
