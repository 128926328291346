import endpoints from './endpoints'
import getApiData from './getApiData'

export async function getRawDataCollection({
  token,
  farmId,
  year,
  namespace,
  sort,
  page = 0,
  size = 10,
}) {
  try {
    const rawDataCollection = await getApiData(
      endpoints.v2.rawDataCollection,
      {
        farmId,
        year,
        namespace,
        sort,
        page,
        size,
      },
      token,
    )
    return rawDataCollection
  } catch (error) {
    console.error(error)
    throw new Error('Unable to get raw data collection')
  }
}
