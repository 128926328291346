import { IconButton, Menu, MenuItem } from '@mui/material'
import { cloneElement, useState } from 'react'
import { pickChildrenByType } from '../../utils/childrenPickers'

const ChildrenButton = ({ children, ...props }) => {
  return <IconButton {...props}>{children}</IconButton>
}

const ChildrenMenuItem = ({ children, onClick, handleClose, ...props }) => {
  const handleClick = () => {
    onClick?.()
    handleClose()
  }
  return (
    <MenuItem onClick={handleClick} {...props}>
      {children}
    </MenuItem>
  )
}

export const MenuButton = ({ id, children }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      {pickChildrenByType(children, ChildrenButton).map(child =>
        cloneElement(child, {
          'aria-label': 'menu-button',
          'aria-controls': open ? 'menu-button' : undefined,
          'aria-expanded': open ? 'true' : undefined,
          'aria-haspopup': 'true',
          onClick: handleClick,
        }),
      )}
      <Menu
        anchorEl={anchorEl}
        id={id}
        MenuListProps={{
          'aria-labelledby': 'menu-button',
        }}
        open={open}
        onClose={handleClose}
      >
        {pickChildrenByType(children, ChildrenMenuItem).map(child =>
          cloneElement(child, { handleClose }),
        )}
      </Menu>
    </div>
  )
}

MenuButton.Button = ChildrenButton
MenuButton.Item = ChildrenMenuItem
