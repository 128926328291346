/* eslint-disable no-shadow */
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { useContext, useEffect, useState } from 'react'
import JSZip from 'jszip'
import AppContext from '../../context/appContext'
import { errors } from '../../services/ruutsApi/errors'
import { getMapaToDownload } from '../../services/ruutsApi/getMapaToDownload'
import { useProcessLoading } from '../../utils/Loading/useProcessLoading'
import { a11yProps } from '../../utils/tabsHandle'
import FarmMapping from '../FarmMapping/FarmMapping'
import PerimeterHome from '../FarmPerimeter/PerimeterHome'
import TabPanel from '../NavBar/TabPanel'
import { downloadFile } from '../../utils/downloadFile'

const perimeterValidationWorkflowId = 4

const FarmData = () => {
  const {
    partialChanges,
    setPartialChanges,
    setConfirmationModalConfig,
    currentFarm,
    programConfig,
  } = useContext(AppContext)

  const [value, setValue] = useState(0)
  const [actualYearId, setActualYearId] = useState('')
  const [saved, setSaved] = useState(false)
  const [fileType, setFileType] = useState('')
  const [includeSoilInfo, setIncludeSoilInfo] = useState(false)
  const [includeProjectArea, setIncludeProjectArea] = useState(false)
  const [mapWidth] = useState((window.innerWidth / 3) * 2)
  const [mapHeight] = useState(window.innerHeight - 100)
  const [showPerimeterTab, setShowPerimeterTab] = useState(false)
  const [tabIndex, setTabIndex] = useState(null)
  const { processLoading } = useProcessLoading()

  const handleChange = (_, newValue) => {
    if (partialChanges) {
      setConfirmationModalConfig({
        open: true,
        title: 'Cambios sin guardar',
        message: 'Si cambia de pantalla, se perderán los cambios. ¿Desea continuar?',
        confirmLabel: 'Continuar',
        cancelLabel: 'Cancelar',
        confirmAction: () => {
          setPartialChanges(false)
          setValue(newValue)
        },
      })
    } else {
      setValue(newValue)
    }
  }

  const handleActualYearId = async id => {
    setActualYearId(id)
  }

  const handleSaved = newValue => {
    setSaved(newValue)
  }

  const handleFileType = async event => {
    setFileType(event.target.value)
  }

  const handleIncludeSoilInfo = async event => {
    setIncludeSoilInfo(event.target.checked)
  }

  const handleIncludeProjectArea = async event => {
    setIncludeProjectArea(event.target.checked)
  }

  const generateZip = async ({ fileName, fileType, fileContent, extraCSVInformation }) => {
    const zip = new JSZip()

    zip.file(`${fileName}.csv`, extraCSVInformation)
    zip.file(`${fileName}.${fileType}`, fileContent)

    return zip.generateAsync({ type: 'blob' })
  }

  const handleDownload = async () => {
    await processLoading({
      loadingMessage: 'Descargando su mapa.',
      successfulMessage: 'Mapa descargado exitosamente',
      errorMessage: 'Error al descargar el mapa, por favor intente nuevamente',
      doAction: async ({ token }) => {
        if (saved && currentFarm) {
          const map = await getMapaToDownload({
            currentFarm,
            token,
            actualYearId,
            includeSoilInfo,
            includeProjectArea,
          })
          let blobFarm = null
          switch (fileType) {
            case 'kml': {
              blobFarm = new Blob([map?.kml], { type: 'text/plain' })
              break
            }
            case 'geojson': {
              blobFarm = new Blob([map?.geojson], { type: 'text/plain' })
              break
            }
            default:
              throw new errors.BusinessEntityError({ message: 'Tipo de archivo no soportado' })
          }

          let downloadFileName = null
          if (includeSoilInfo) {
            blobFarm = await generateZip({
              fileName: currentFarm.name,
              fileType,
              fileContent: blobFarm,
              extraCSVInformation: map?.csv,
            })
            downloadFileName = `${currentFarm.name}.zip`
          } else {
            downloadFileName = `${currentFarm.name}.${fileType}`
          }

          downloadFile({ fileName: downloadFileName, file: blobFarm })
        }
      },
    })
  }

  useEffect(() => {
    if (!currentFarm || !programConfig) return

    const handleYearsUploaded = async () => {
      await processLoading({
        loadingMessage: 'Cargando el establecimiento',
        successfulMessage: '',
        errorMessage: 'Error cargando el establecimiento.',
        doAction: async ({ _ }) => {
          const showPerimeterTab = programConfig?.monitoringWorkflowIds.includes(
            perimeterValidationWorkflowId,
          )
          const tabIndex = showPerimeterTab ? { mapping: 0, perimeter: 1 } : { mapping: 0 }
          setShowPerimeterTab(showPerimeterTab)
          setTabIndex(tabIndex)
        },
      })
    }

    handleYearsUploaded()
  }, [currentFarm, programConfig, processLoading])

  return (
    <>
      {tabIndex && (
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs aria-label="basic tabs example" gap={2} value={value} onChange={handleChange}>
              <Tab label="Mapeo" sx={{ textTransform: 'none' }} {...a11yProps(tabIndex.mapping)} />
              {showPerimeterTab && (
                <Tab
                  label="Perímetro"
                  sx={{ textTransform: 'none' }}
                  {...a11yProps(tabIndex.perimeter)}
                />
              )}
            </Tabs>
          </Box>
          {/* Tabs Panels */}
          <TabPanel index={tabIndex.mapping} value={value}>
            <FarmMapping
              actualYearId={actualYearId}
              fileType={fileType}
              handleActualYearId={handleActualYearId}
              handleDownload={handleDownload}
              handleFileType={handleFileType}
              handleIncludeProjectArea={handleIncludeProjectArea}
              handleIncludeSoilInfo={handleIncludeSoilInfo}
              handleSaved={handleSaved}
              includeProjectArea={includeProjectArea}
              includeSoilInfo={includeSoilInfo}
              saved={saved}
            />
          </TabPanel>
          {showPerimeterTab && (
            <TabPanel index={tabIndex.perimeter} value={value}>
              <PerimeterHome farm={currentFarm} mapHeight={mapHeight} mapWidth={mapWidth} />
            </TabPanel>
          )}
        </Box>
      )}
    </>
  )
}

export default FarmData
