export const formModes = {
  View: {
    label: 'VIENDO',
    readOnly: true,
  },
  Edit: {
    label: 'EDITANDO',
    readOnly: false,
  },
  Review: {
    label: 'REVISANDO',
    readOnly: true,
  },
  Disable: {
    label: 'DESHABILITADO',
    readOnly: true,
  },
}

export const findingsTypes = {
  Observation: 0,
  NonComplaintToFix: 1,
  NonComplaintSevere: 2,
}

export const PROGRAMS = {
  0: 'SARA',
  1: 'POA',
}

export const PROGRAM_IDS = {
  SARA: 0,
  POA: 1,
}

export const metricEventStatus = {
  Open: 0,
  Close: 1,
}
