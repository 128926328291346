export const downloadFile = ({ fileName, content, mimeType, file }) => {
  if (file && content) {
    throw new Error('Only one of file or content can be provided')
  }
  let finalFile = file

  if (content) {
    const fileOptions = mimeType ? { type: mimeType } : {}
    finalFile = new Blob([content], fileOptions)
  }

  const link = document.createElement('a')
  link.href = URL.createObjectURL(finalFile)
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
