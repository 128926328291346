/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { useAuth0 } from '@auth0/auth0-react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from '@mui/material'
import { useSnackbar } from 'notistack'
import { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppContext from '../../../context/appContext'
import lfStore from '../../../lfstore/lfStore'
import { checkFeaturesOverlap } from '../../../services/farmMapping/checkFeaturesOverlap.js'
import getFarmSubdivisions from '../../../services/farmMapping/getFarmSubdivision'
import { saveFarmMapping } from '../../../services/farmMapping/saveFarmMapping'
import { ruutsApi } from '../../../services/ruutsApi'
import { getSelectedYears } from '../../../utils/FarmLayers/exportMapeo'
import {
  deleteOutsiderFeatures,
  perimetersToMultiPolygon,
} from '../../../utils/FarmLayers/perimeter'
import { useProcessLoading } from '../../../utils/Loading/useProcessLoading.js'
import { customSnackbarError } from '../../../utils/Snackbar/Snackbar'
import { getRandomColor } from '../../../utils/mapDrawHandle'
import { messages } from '../../../utils/messages/index.js'
import { userRoles } from '../../../utils/userRoles'
import CategorizationConfirm from '../modals/CategorizationConfirm'
import AccordionHeader from './AccordionHeader'
import AlertBanner from './AlertBanner'
import AreaRow from './AreaRow'
import YearSelector from './YearSelector'

const categoriesPanels = {
  exclusionAreas: {
    type: 'exclusionAreas',
    panelName: 'exclusionAreasPanel',
  },
  monitoringSites: {
    type: 'monitoringSites',
    panelName: 'monitoringPanel',
  },
  unassigned: {
    type: 'unassigned',
    panelName: 'unassignedPanel',
  },
  unassignedPoints: {
    type: 'unassignedPoints',
    panelName: 'unassignedPointsPanel',
  },
  paddocks: {
    type: 'paddocks',
    panelName: 'paddocksPanel',
  },
  samplingAreas: {
    type: 'estratas',
    panelName: 'samplingAreasPanel',
  },
  perimeter: {
    type: 'perimeter',
    panelName: 'perimeterPanel',
  },
  otherSites: {
    type: 'otherSites',
    panelName: 'otherSitesPanel',
  },
  otherPolygons: {
    type: 'otherPolygons',
    panelName: 'otherPolygonsPanel',
  },
}

const successfulValidation = null

function findInvalidFeaturesNamesIndex(features) {
  // Check if features names has invalid characters
  const charactersAreValid = /^[a-zA-Z0-9\-_()/ñÑáéíóúÁÉÍÓÚ ]+$/ // Only letters, numbers, spaces, and - _ ( ) /

  // Check if feature names has more then one continued spaces
  const hasMoreThanOneContinuedSpaces = / {2,}/

  return features
    .map((feature, index) => ({ ...feature, index }))
    .filter(
      feature =>
        !charactersAreValid.test(feature.properties.name) ||
        hasMoreThanOneContinuedSpaces.test(feature.properties.name),
    )
    .map(feature => feature.index)
}

function findDuplicatesFeaturesNamesIndexes(array) {
  if (!array?.length) return []

  const duplicates = array.reduce((nameIndexes, { properties: { name } }, index) => {
    nameIndexes[name] = nameIndexes[name] ? [...nameIndexes[name], index] : [index]
    return nameIndexes
  }, {})

  // Filter out non-duplicate entries
  const duplicateIndexes = Object.values(duplicates)
    .filter(indexes => indexes.length > 1)
    .flat()

  return duplicateIndexes
}

function findDuplicatesFeaturesNamesAndExclusionAreaTypeIdIndexes(array) {
  if (!array?.length) return []

  const duplicates = array.reduce(
    (indexesByName, { properties: { name, exclusionAreaTypeId } }, index) => {
      const uniqueName = `${name}-${exclusionAreaTypeId}`
      indexesByName[uniqueName] = indexesByName[uniqueName]
        ? [...indexesByName[uniqueName], index]
        : [index]
      return indexesByName
    },
    {},
  )

  // Filter out non-duplicate entries
  const duplicateIndexes = Object.values(duplicates)
    .filter(indexes => indexes.length > 1)
    .flat()

  return duplicateIndexes
}

const ShowFolders = ({
  etapa,
  handleEtapa,
  perimeter,
  handlePerimeter,
  paddocks,
  handlePaddocks,
  samplingAreas,
  handleSamplingAreas,
  monitoringSites,
  handleMonitoringSites,
  unassigned,
  handleUnassigned,
  unassignedPoints,
  handleUnassignedPoints,
  exclusionAreas,
  handleExclusionAreas,
  handleMapUpdate,
  center,
  otherPolygons,
  handleOtherPolygons,
  otherSites,
  handleOtherSites,
  actualYearId,
  handleActualYearId,
  saved,
  handleSaved,
  fileType,
  handleFileType,
  includeSoilInfo,
  handleIncludeSoilInfo,
  includeProjectArea,
  handleIncludeProjectArea,
  handleDownload,
}) => {
  const { programConfig, currentFarm, setCurrentFarm, setPartialChanges, userRoleSelected } =
    useContext(AppContext)

  const [alert, setAlert] = useState(false)
  const [modalConfirmationOpen, setModalConfirmationOpen] = useState(false)
  const [years, setYears] = useState([])
  const [downloadableYears, setDownloadableYears] = useState([])
  const [savingDependencies, setSavingDependencies] = useState([])
  const [expandedPanels, setExpandedPanels] = useState({})
  const [perimeterValidation, setPerimeterValidation] = useState(successfulValidation)
  const [exclusionAreasValidation, setExclusionAreasValidation] = useState(successfulValidation)
  const [unassignedValidation] = useState(successfulValidation)
  const [paddocksValidation, setPaddocksValidation] = useState(successfulValidation)
  const [samplingAreasValidation, setSamplingAreasValidation] = useState(successfulValidation)
  const [monitoringSitesValidation, setMonitoringSitesValidation] = useState(successfulValidation)
  const [otherSitesValidation] = useState(successfulValidation)
  const [otherPolygonsValidation] = useState(successfulValidation)
  const [unassignedPointsValidation, setUnassignedPointsValidation] = useState(successfulValidation)
  const [featuresAreValid, setFeaturesAreValid] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { processLoading } = useProcessLoading()

  const { user, getAccessTokenSilently } = useAuth0()

  const navigate = useNavigate()

  const handleOpen = () => {
    setModalConfirmationOpen(true)
  }
  const handleClose = () => {
    setModalConfirmationOpen(false)
  }

  const handleUpdateCurrentFarm = updatedFarm => {
    setCurrentFarm(updatedFarm)
    lfStore.setItem('currentFarm', updatedFarm)
  }

  const handleSaveCategorization = async () => {
    await processLoading({
      loadingMessage: 'Guardando establecimiento...',
      doAction: async ({ token }) => {
        const selectedYears = getSelectedYears(years, etapa)

        const multiPolygonPerimeter = perimetersToMultiPolygon(perimeter)

        const filteredLayers = deleteOutsiderFeatures(
          multiPolygonPerimeter,
          paddocks,
          handlePaddocks,
          samplingAreas,
          handleSamplingAreas,
          monitoringSites,
          handleMonitoringSites,
          exclusionAreas,
          handleExclusionAreas,
          otherPolygons,
          handleOtherPolygons,
          otherSites,
          handleOtherSites,
        )

        // If there is sampling area superposition, show alert and throw an error to stop processLoading process
        checkFeaturesOverlap(filteredLayers.samplingAreas)

        handleUnassigned(null)
        handleUnassignedPoints(null)

        // If there is no superposition, save
        await saveFarmMapping(
          currentFarm,
          user,
          token,
          selectedYears,
          multiPolygonPerimeter,
          filteredLayers.paddocks,
          filteredLayers.samplingAreas,
          filteredLayers.monitoringSites,
          filteredLayers.exclusionAreas,
          center,
          filteredLayers.otherPolygons,
          filteredLayers.otherSites,
        )

        const updatedFarm = await ruutsApi.getFarm({ id: currentFarm.id, user, token })
        handleUpdateCurrentFarm(updatedFarm)

        handleSaved(true)
        setPartialChanges(false)
      },
    })
    handleClose()
  }

  const handleYears = newYears => {
    setYears(newYears)
  }

  const handleCancelShowFolders = () => {
    handlePerimeter(null)
    handlePaddocks(null)
    handleSamplingAreas(null)
    handleMonitoringSites(null)
    handleExclusionAreas(null)
    handleUnassigned(null)
    handleUnassignedPoints(null)
    handleOtherPolygons(null)
    handleOtherSites(null)
    handleEtapa('inicial')
    setPartialChanges(false)
    handleMapUpdate()
  }

  const handleCloseAlert = () => {
    setAlert(false)
    lfStore.setItem('storedMapeoDone', false)
  }

  const handleLayerTypeChange = (newFeature, oldType, newType) => {
    // remove feature from the oldType
    switch (oldType) {
      case 'perimeter':
        const newPerimeters = perimeter.filter(p => {
          return p.properties.id !== newFeature.properties.id
        })
        handlePerimeter(newPerimeters)
        break
      case 'paddocks':
        const newPaddocks = paddocks.filter(p => {
          return p.properties.id !== newFeature.properties.id
        })
        handlePaddocks(newPaddocks)
        break
      case 'estratas':
        const newSamplingAreas = samplingAreas.filter(p => {
          return p.properties.id !== newFeature.properties.id
        })
        handleSamplingAreas(newSamplingAreas)
        break
      case 'exclusionAreas':
        const newExclusionAreas = exclusionAreas.filter(p => {
          return p.properties.id !== newFeature.properties.id
        })
        delete newFeature.properties.exclusionAreaTypeId
        handleExclusionAreas(newExclusionAreas)
        break
      case 'otherPolygons':
        const newOtherPolygons = otherPolygons.filter(p => {
          return p.properties.id !== newFeature.properties.id
        })
        handleOtherPolygons(newOtherPolygons)
        break
      case 'otherSites':
        const newOtherSites = otherSites.filter(p => {
          return p.properties.id !== newFeature.properties.id
        })
        handleOtherSites(newOtherSites)
        break
      case 'monitoringSites':
        const newMonitoringSites = monitoringSites.filter(p => {
          return p.properties.id !== newFeature.properties.id
        })
        handleMonitoringSites(newMonitoringSites)
        break
      case 'unassigned':
        const newUnassigned = unassigned.filter(p => {
          return p.properties.id !== newFeature.properties.id
        })
        handleUnassigned(newUnassigned)
        break
      case 'unassignedPoints':
        const newUnassignedPoints = unassignedPoints.filter(p => {
          return p.properties.id !== newFeature.properties.id
        })
        handleUnassignedPoints(newUnassignedPoints)
        break
      default:
        break
    }

    // add feature to the newType
    switch (newType) {
      case 'perimeter':
        const newPerimeters = perimeter?.length ? [...perimeter] : []
        newFeature.properties.featureGroup = 'perimeter'
        newPerimeters.push(newFeature)
        handlePerimeter(newPerimeters)
        break
      case 'paddocks':
        const newPaddocks = paddocks?.length ? [...paddocks] : []
        newFeature.properties.featureGroup = 'paddocks'
        newPaddocks.push(newFeature)
        handlePaddocks(newPaddocks)
        break
      case 'estratas':
        const newEstratas = samplingAreas?.length ? [...samplingAreas] : []
        // Add color
        if (!newFeature.properties.color) {
          const color = getRandomColor()
          newFeature.properties.color = color
        }
        newEstratas.push(newFeature)
        handleSamplingAreas(newEstratas)
        break
      case 'exclusionAreas':
        const newExclusionAreas = exclusionAreas?.length ? [...exclusionAreas] : []
        newFeature.properties.featureGroup = 'exclusionAreas'
        newExclusionAreas.push(newFeature)
        handleExclusionAreas(newExclusionAreas)
        break
      case 'otherPolygons':
        const newOtherPolygons = otherPolygons ? [...otherPolygons] : []
        newFeature.properties.featureGroup = 'otherPolygons'
        newOtherPolygons.push(newFeature)
        handleOtherPolygons(newOtherPolygons)
        break
      case 'unassigned':
        const newUnassigned = unassigned ? [...unassigned] : []
        newFeature.properties.featureGroup = 'unassigned'
        newUnassigned.push(newFeature)
        handleUnassigned(newUnassigned)
        break
      case 'monitoringSites':
        const newMonitoringSites = monitoringSites?.length ? [...monitoringSites] : []
        newFeature.properties.featureGroup = 'monitoringSites'
        newMonitoringSites.push(newFeature)
        handleMonitoringSites(newMonitoringSites)
        break
      case 'otherSites':
        const newOtherSites = otherSites?.length ? [...otherSites] : []
        newFeature.properties.featureGroup = 'otherSites'
        newOtherSites.push(newFeature)
        handleOtherSites(newOtherSites)
        break
      case 'unassignedPoints':
        const newUnassignedPoints = unassignedPoints?.length ? [...unassignedPoints] : []
        newFeature.properties.featureGroup = 'unassignedPoints'
        newUnassignedPoints.push(newFeature)
        handleUnassignedPoints(newUnassignedPoints)
        break
      default:
        break
    }

    handleMapUpdate()
  }

  const handleExclusionAreaTypeChange = (newFeature, newType) => {
    const newExclusionAreas = exclusionAreas.map(exclusionArea => {
      if (exclusionArea.properties.id !== newFeature.properties.id) {
        return exclusionArea
      }
      return {
        type: 'Feature',
        properties: {
          id: newFeature.properties.id,
          name: newFeature.properties.name,
          area: newFeature.properties.area,
          featureGroup: 'exclusionAreas',
          exclusionAreaTypeId: newType,
          hasGrazingManagement: newFeature.properties.hasGrazingManagement === true,
        },
        geometry: {
          type: newFeature.geometry.type,
          coordinates: newFeature.geometry.coordinates,
        },
      }
    })
    handleExclusionAreas(newExclusionAreas)

    handleMapUpdate()
  }

  const handleName = (newFeature, type, newName) => {
    switch (type) {
      case 'paddocks':
        const newPaddocks = paddocks.map(paddock => {
          if (paddock.properties.id !== newFeature.properties.id) {
            return paddock
          }
          const newPaddock = paddock
          newPaddock.properties.name = newName
          return newPaddock
        })
        handlePaddocks(newPaddocks)
        break
      case 'estratas':
        const newSamplingAreas = samplingAreas.map(samplingArea => {
          if (samplingArea.properties.id !== newFeature.properties.id) {
            return samplingArea
          }
          const newSamplingArea = samplingArea
          newSamplingArea.properties.name = newName
          return newSamplingArea
        })
        handleSamplingAreas(newSamplingAreas)
        break
      case 'exclusionAreas':
        const newExclusionAreas = exclusionAreas.map(exclusionArea => {
          if (exclusionArea.properties.id !== newFeature.properties.id) {
            return exclusionArea
          }
          const newExclusionArea = exclusionArea
          newExclusionArea.properties.name = newName
          return newExclusionArea
        })
        handleExclusionAreas(newExclusionAreas)
        break
      case 'otherPolygons':
        const newOtherPolygons = otherPolygons.map(otherPolygon => {
          if (otherPolygon.properties.id !== newFeature.properties.id) {
            return otherPolygon
          }
          const newOtherPolygon = otherPolygon
          newOtherPolygon.properties.name = newName
          return newOtherPolygon
        })
        handleOtherPolygons(newOtherPolygons)
        break
      case 'unassigned':
        const newUnassigned = unassigned.map(u => {
          if (u.properties.id !== newFeature.properties.id) {
            return u
          }
          const newUnassignedArea = u
          newUnassignedArea.properties.name = newName
          return newUnassignedArea
        })
        handleUnassigned(newUnassigned)
        break
      case 'unassignedPoints':
        const newUnassignedPoints = unassignedPoints.map(unassignedPoint => {
          if (unassignedPoint.properties.id !== newFeature.properties.id) {
            return unassignedPoint
          }
          const newUnassignedPoint = unassignedPoint
          newUnassignedPoint.properties.name = newName
          return newUnassignedPoint
        })
        handleUnassignedPoints(newUnassignedPoints)
        break
      default:
        break
    }

    handleMapUpdate()
  }

  const handleOtherName = (newFeature, newOtherName) => {
    const newExclusionAreas = exclusionAreas.map(exclusionArea => {
      if (exclusionArea.properties.id !== newFeature.properties.id) {
        return exclusionArea
      }
      const newExclusionArea = exclusionArea
      newExclusionArea.properties.otherName = newOtherName
      return newExclusionArea
    })
    handleExclusionAreas(newExclusionAreas)

    handleMapUpdate()
  }

  const handleHasGrazingManagement = (newFeature, newHasGrazingManagement) => {
    try {
      const newExclusionAreas = exclusionAreas.map(exclusionArea => {
        if (exclusionArea.properties.id === newFeature.properties.id) {
          exclusionArea.properties.hasGrazingManagement = newHasGrazingManagement
        }
        return exclusionArea
      })

      handleExclusionAreas(newExclusionAreas)

      handleMapUpdate()
    } catch (error) {
      customSnackbarError(
        'Error al cambiar el uso de manejo, por favor intente nuevamente',
        error,
        enqueueSnackbar,
        closeSnackbar,
      )
    }
  }

  const validatePaddocksFeatures = useCallback(
    pads => {
      if (!pads?.length) {
        setPaddocksValidation(null)
        return true
      }

      // Check years selected
      if (years && !saved) {
        let yearsMissing = pads && pads.length > 0
        years.forEach(year => {
          if (year.selected) {
            yearsMissing = false
          }
        })
        if (yearsMissing) {
          setPaddocksValidation({
            errorsIndexes: [],
            message: 'Debe seleccionar al menos un año',
          })
          return false
        }
      }

      // Check if paddock names has invalid characters
      let errorsIndexes = findInvalidFeaturesNamesIndex(pads)
      if (errorsIndexes.length > 0) {
        setPaddocksValidation({
          errorsIndexes,
          message: messages.getMessage(messages.keys.ERROR_INVALID_LAYER_NAME_MESSAGE),
        })
        return false
      }

      // Check if paddock names are duplicated
      errorsIndexes = findDuplicatesFeaturesNamesIndexes(pads)
      if (errorsIndexes.length > 0) {
        setPaddocksValidation({
          errorsIndexes,
          message: messages.getMessage(
            messages.keys.ERROR_CANNOT_EDIT_CATEGORY_WITH_DUPLICATES_NAMES_MESSAGE,
            [messages.keys.FARM_SUBDIVISION_GEOMETRY_TYPE],
          ),
        })
        return false
      }

      setPaddocksValidation(null)
      return true
    },
    [years, saved],
  )

  useEffect(() => {
    if (!saved) validatePaddocksFeatures(paddocks)
  }, [saved, validatePaddocksFeatures, paddocks])

  const validateSamplingAreasFeatures = useCallback(sA => {
    if (!sA?.length) {
      setSamplingAreasValidation(null)
      return true
    }

    let errorsIndexes = findInvalidFeaturesNamesIndex(sA)
    if (errorsIndexes.length > 0) {
      setSamplingAreasValidation({
        errorsIndexes,
        message: messages.getMessage(messages.keys.ERROR_INVALID_LAYER_NAME_MESSAGE),
      })
      return false
    }

    // Check if paddock names are duplicated
    errorsIndexes = findDuplicatesFeaturesNamesIndexes(sA)
    if (errorsIndexes.length > 0) {
      setSamplingAreasValidation({
        errorsIndexes,
        message: messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_CATEGORY_WITH_DUPLICATES_NAMES_MESSAGE,
          [messages.keys.SAMPLING_AREA_GEOMETRY_TYPE],
        ),
      })
      return false
    }

    setSamplingAreasValidation(null)
    return true
  }, [])

  useEffect(() => {
    if (!saved) validateSamplingAreasFeatures(samplingAreas)
  }, [saved, validateSamplingAreasFeatures, samplingAreas])

  const validateExclusionAreasFeatures = useCallback(eA => {
    if (!eA?.length) {
      setExclusionAreasValidation(null)
      return true
    }

    // Check if exclusion names has invalid characters
    let errorsIndexes = findInvalidFeaturesNamesIndex(eA)
    if (errorsIndexes.length > 0) {
      setExclusionAreasValidation({
        errorsIndexes,
        message: messages.getMessage(messages.keys.ERROR_INVALID_LAYER_NAME_MESSAGE),
      })
      return false
    }

    // Check duplicate on exclusion type and name
    errorsIndexes = findDuplicatesFeaturesNamesAndExclusionAreaTypeIdIndexes(eA)
    if (errorsIndexes?.length > 0) {
      setExclusionAreasValidation({
        errorsIndexes,
        message: messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_CATEGORY_WITH_DUPLICATES_NAMES_AND_TYPE_MESSAGE,
          [messages.keys.EXCLUSION_AREA_GEOMETRY_TYPE],
        ),
      })
      return false
    }

    const unassignedExclusionAreaTypesIndexes = eA
      ?.map((exclusionArea, index) =>
        exclusionArea?.properties?.exclusionAreaTypeId === undefined ? index : undefined,
      )
      .filter(index => index !== undefined)

    if (unassignedExclusionAreaTypesIndexes?.length > 0) {
      setExclusionAreasValidation({
        errorsIndexes: unassignedExclusionAreaTypesIndexes,
        message: 'Se debe asignar el tipo de área de exclusión',
      })
      return false
    }

    setExclusionAreasValidation(null)
    return true
  }, [])

  useEffect(() => {
    if (!saved) validateExclusionAreasFeatures(exclusionAreas)
  }, [saved, validateExclusionAreasFeatures, exclusionAreas])

  const validatePerimeterFeature = useCallback(per => {
    if (!per?.length) {
      setPerimeterValidation({
        message: messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_FARM_PERIMETER_REQUIRED_MESSAGE,
        ),
      })
      return false
    }

    const invalidPerimetersIndexes = per
      ?.map((p, index) => (p?.geometry !== undefined ? index : undefined))
      .filter(index => index !== undefined)

    if (!invalidPerimetersIndexes?.length) {
      setPerimeterValidation({
        errorsIndexes: invalidPerimetersIndexes,
        message: messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_FARM_PERIMETER_REQUIRED_MESSAGE,
        ),
      })
      return false
    }

    setPerimeterValidation(null)
    return true
  }, [])

  useEffect(() => {
    if (!saved) validatePerimeterFeature(perimeter)
  }, [saved, validatePerimeterFeature, perimeter])

  const validateUnassignedPointsFeatures = useCallback(uP => {
    if (!uP?.length) {
      setUnassignedPointsValidation(null)
      return true
    }

    const errorsIndexes = findDuplicatesFeaturesNamesIndexes(uP)
    if (errorsIndexes?.length > 0) {
      setUnassignedPointsValidation({
        errorsIndexes,
        message: messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_CATEGORY_WITH_DUPLICATES_NAMES_MESSAGE,
          [messages.keys.UNSIGNED_POINT_GEOMETRY_TYPE],
        ),
      })
      return false
    }

    setUnassignedPointsValidation(null)
    return true
  }, [])

  useEffect(() => {
    if (!saved) validateUnassignedPointsFeatures(unassignedPoints)
  }, [saved, validateUnassignedPointsFeatures, unassignedPoints])

  const validateMonitoringSitesFeatures = useCallback(mS => {
    if (!mS?.length) {
      setMonitoringSitesValidation(null)
      return true
    }

    const errorsIndexes = findDuplicatesFeaturesNamesIndexes(mS)
    if (errorsIndexes?.length > 0) {
      setMonitoringSitesValidation({
        errorsIndexes,
        message: messages.getMessage(
          messages.keys.ERROR_CANNOT_EDIT_CATEGORY_WITH_DUPLICATES_NAMES_MESSAGE,
          [messages.keys.MONITORING_SITE_GEOMETRY_TYPE],
        ),
      })
      return false
    }

    setMonitoringSitesValidation(null)
    return true
  }, [])

  useEffect(() => {
    if (!saved) validateMonitoringSitesFeatures(monitoringSites)
  }, [saved, monitoringSites, validateMonitoringSitesFeatures])

  const handleCheckForRequiredFields = () => {
    const featuresToValidate = [
      { feature: perimeter, validator: validatePerimeterFeature },
      { feature: unassignedPoints, validator: validateUnassignedPointsFeatures },
      { feature: paddocks, validator: validatePaddocksFeatures },
      { feature: samplingAreas, validator: validateSamplingAreasFeatures },
      { feature: exclusionAreas, validator: validateExclusionAreasFeatures },
      { feature: monitoringSites, validator: validateMonitoringSitesFeatures },
    ]

    const allValid = featuresToValidate.every(({ validator, feature }) => validator(feature))

    if (allValid) {
      handleOpen()
    }
  }

  const handleAddDependencies = newDependency => {
    const newDependencies = savingDependencies.map(dependency => {
      return dependency
    })
    newDependencies.push(newDependency)
    setSavingDependencies(newDependencies)
  }

  const handleRemoveDependencies = newDependency => {
    const newDependencies = savingDependencies
      .map(dependency => {
        if (dependency !== newDependency) {
          return dependency
        }
        return null
      })
      .filter(dependency => {
        return dependency !== null
      })
    setSavingDependencies(newDependencies)
  }

  const handleRemoveFeature = (newFeature, type) => {
    if (type === 'unassigned') {
      const newUnassigned = unassigned
        .map(u => {
          if (u.properties.id !== newFeature.properties.id) {
            return u
          }
          return null
        })
        .filter(u => {
          return u !== null
        })
      handleUnassigned(newUnassigned)
    } else if (type === 'unassignedPoints') {
      const newUnassignedPoints = unassignedPoints
        .map(unassignedPoint => {
          if (unassignedPoint.properties.id !== newFeature.properties.id) {
            return unassignedPoint
          }
          return null
        })
        .filter(unassignedPoint => {
          return unassignedPoint !== null
        })
      handleUnassignedPoints(newUnassignedPoints)
    }
  }

  useEffect(() => {
    const handleYearsUploaded = async () => {
      const token = await getAccessTokenSilently()
      const subdivisions = await getFarmSubdivisions(currentFarm, token)

      const y = subdivisions.map(subdivision => {
        return {
          id: subdivision.id,
          value: subdivision.year,
        }
      })

      setDownloadableYears(y)
    }

    if (saved && currentFarm) {
      handleYearsUploaded()
    }
  }, [currentFarm, getAccessTokenSilently, saved])

  useEffect(() => {
    if (years.length === 0) {
      const newYears = programConfig.farmSubdivisionsYearsAllowed
        .filter(farmSubdivisionYearAllowed => !Number.isNaN(farmSubdivisionYearAllowed)) // On KML mapeo creation, only years are allowed (Not ex-posted years)
        .map(farmSubdivisionYearAllowed => {
          return { value: farmSubdivisionYearAllowed, selected: false }
        })
      setYears(newYears)
    }
  }, [years, programConfig])

  useEffect(() => {
    const getCachedData = async () => {
      const alertStatus = await lfStore.getItem('storedMapeoDone')
      setAlert([null, undefined].includes(alertStatus) ? true : alertStatus)
    }
    getCachedData()
  }, [alert])

  useEffect(() => {
    if (etapa === 'showingFoldersApi') {
      handleSaved(true)
    }
  }, [etapa, handleSaved])

  useEffect(() => {
    if (saved && downloadableYears.length > 0 && !actualYearId) {
      const highestYear = downloadableYears.reduce((prev, current) => {
        return prev.value > current.value ? prev : current
      })
      handleActualYearId(highestYear.id)
    }
  }, [downloadableYears, actualYearId, handleActualYearId, saved])

  useEffect(() => {
    const validations = [
      perimeterValidation,
      exclusionAreasValidation,
      unassignedValidation,
      paddocksValidation,
      samplingAreasValidation,
      monitoringSitesValidation,
      unassignedPointsValidation,
    ]

    setFeaturesAreValid(validations.every(validation => !validation))
  }, [
    perimeterValidation,
    exclusionAreasValidation,
    unassignedValidation,
    paddocksValidation,
    samplingAreasValidation,
    monitoringSitesValidation,
    unassignedPointsValidation,
  ])

  const handleChange = panel => () => {
    const newHandlePanels = { ...expandedPanels }
    // Toggle the panel panel selected in order to expand or collapse it
    newHandlePanels[panel.panelName] = !newHandlePanels[panel.panelName]
    setExpandedPanels(newHandlePanels)
  }

  return (
    <Box
      sx={{
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: saved ? window.innerHeight - 100 : window.innerHeight - 160,
      }}
    >
      <AlertBanner
        alert={alert}
        etapa={etapa}
        exclusionAreas={exclusionAreas}
        handleCloseAlert={handleCloseAlert}
        paddocks={paddocks}
        perimeter={perimeter}
        samplingAreas={samplingAreas}
        saved={saved}
        unassigned={unassigned}
      />
      <Box
        sx={{
          width: '100%',
          padding: '10px',
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          minHeight: '125px',
        }}
      >
        {saved && downloadableYears.length > 0 ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              py: 1,
              px: 4,
            }}
            width="100%"
          >
            <Typography
              minWidth="150px"
              sx={{
                fontSize: 14,
                fontWeight: 600,
                pr: 2,
              }}
            >
              Seleccionar el año del loteo a visualizar
            </Typography>
            <Box sx={{ width: '100px' }}>
              <FormControl fullWidth>
                <InputLabel id="year-select-label">Año</InputLabel>
                <Select
                  id="year-select"
                  label="Año"
                  labelId="year-select-label"
                  value={actualYearId}
                  onChange={e => {
                    handleActualYearId(e.target.value)
                  }}
                >
                  {downloadableYears.map(year => (
                    <MenuItem key={year.id} value={year.id}>
                      {year.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        ) : null}
        {unassigned?.length > 0 && !saved && (
          <LayerPanel
            categoryPanel={categoriesPanels.unassigned}
            color="#f5bfba"
            elements={unassigned}
            expandedPanels={expandedPanels}
            handleChange={handleChange}
            handleFeatureChange={handleUnassigned}
            handleLayerTypeChange={handleLayerTypeChange}
            handleMapUpdate={handleMapUpdate}
            handleName={handleName}
            handleRemoveFeature={handleRemoveFeature}
            perimeter={perimeter}
            saved={saved}
            title="Areas sin asignar"
            validation={unassignedValidation}
          />
        )}
        {unassignedPoints?.length > 0 && !saved && (
          <LayerPanel
            categoryPanel={categoriesPanels.unassignedPoints}
            color="#f5bfba"
            elements={unassignedPoints}
            expandedPanels={expandedPanels}
            handleChange={handleChange}
            handleFeatureChange={handleUnassignedPoints}
            handleLayerTypeChange={handleLayerTypeChange}
            handleMapUpdate={handleMapUpdate}
            handleName={handleName}
            handleRemoveFeature={handleRemoveFeature}
            perimeter={perimeter}
            saved={saved}
            title="Puntos sin asignar"
            validation={unassignedPointsValidation}
          />
        )}
        {perimeter?.length > 0 && (
          <LayerPanel
            categoryPanel={categoriesPanels.perimeter}
            color="#f5f2ba"
            elements={perimeter}
            expandedPanels={expandedPanels}
            handleChange={handleChange}
            handleFeatureChange={handlePerimeter}
            handleLayerTypeChange={handleLayerTypeChange}
            handleMapUpdate={handleMapUpdate}
            handleName={handleName}
            perimeter={perimeter}
            saved={saved}
            title="Perímetro"
            validation={perimeterValidation}
          />
        )}
        {paddocks?.length > 0 && (
          <LayerPanel
            categoryPanel={categoriesPanels.paddocks}
            color="#dcbfd4"
            elements={paddocks}
            expandedPanels={expandedPanels}
            handleChange={handleChange}
            handleFeatureChange={handlePaddocks}
            handleLayerTypeChange={handleLayerTypeChange}
            handleMapUpdate={handleMapUpdate}
            handleName={handleName}
            perimeter={perimeter}
            saved={saved}
            title="Lotes"
            validation={paddocksValidation}
          >
            {etapa === 'showingFoldersArchivo' && !saved && (
              <YearSelector handleYears={handleYears} years={years} />
            )}
          </LayerPanel>
        )}
        {samplingAreas?.length > 0 && (
          <LayerPanel
            categoryPanel={categoriesPanels.samplingAreas}
            color="#c2d9ba"
            elements={samplingAreas}
            expandedPanels={expandedPanels}
            handleChange={handleChange}
            handleFeatureChange={handleSamplingAreas}
            handleLayerTypeChange={handleLayerTypeChange}
            handleMapUpdate={handleMapUpdate}
            handleName={handleName}
            perimeter={perimeter}
            saved={saved}
            title="Estratos"
            validation={samplingAreasValidation}
          />
        )}
        {exclusionAreas?.length > 0 && (
          <LayerPanel
            categoryPanel={categoriesPanels.exclusionAreas}
            color="#c2bfba"
            elements={exclusionAreas}
            expandedPanels={expandedPanels}
            handleAddDependencies={handleAddDependencies}
            handleChange={handleChange}
            handleExclusionAreaTypeChange={handleExclusionAreaTypeChange}
            handleFeatureChange={handleExclusionAreas}
            handleHasGrazingManagement={handleHasGrazingManagement}
            handleLayerTypeChange={handleLayerTypeChange}
            handleMapUpdate={handleMapUpdate}
            handleName={handleName}
            handleOtherName={handleOtherName}
            handleRemoveDependencies={handleRemoveDependencies}
            perimeter={perimeter}
            saved={saved}
            title="Áreas de exclusión"
            validation={exclusionAreasValidation}
          />
        )}
        {otherPolygons?.length > 0 && (
          <LayerPanel
            categoryPanel={categoriesPanels.otherPolygons}
            color="#f5e6e3"
            elements={otherPolygons}
            expandedPanels={expandedPanels}
            handleChange={handleChange}
            handleFeatureChange={handleOtherPolygons}
            handleLayerTypeChange={handleLayerTypeChange}
            handleMapUpdate={handleMapUpdate}
            handleName={handleName}
            perimeter={perimeter}
            saved={saved}
            title="Otras áreas"
            validation={otherPolygonsValidation}
          />
        )}
        {monitoringSites?.length > 0 && (
          <LayerPanel
            categoryPanel={categoriesPanels.monitoringSites}
            color="#277fca"
            elements={monitoringSites}
            expandedPanels={expandedPanels}
            handleChange={handleChange}
            handleFeatureChange={handleMonitoringSites}
            handleLayerTypeChange={handleLayerTypeChange}
            handleMapUpdate={handleMapUpdate}
            handleName={handleName}
            perimeter={perimeter}
            saved={saved}
            title="Sitios de monitoreo"
            validation={monitoringSitesValidation}
          />
        )}
        {otherSites?.length > 0 && (
          <LayerPanel
            categoryPanel={categoriesPanels.otherSites}
            color="#ffd699"
            elements={otherSites}
            expandedPanels={expandedPanels}
            handleChange={handleChange}
            handleFeatureChange={handleOtherSites}
            handleLayerTypeChange={handleLayerTypeChange}
            handleMapUpdate={handleMapUpdate}
            handleName={handleName}
            perimeter={perimeter}
            saved={saved}
            title="Otros sitios"
            validation={otherSitesValidation}
          />
        )}
        {saved ? (
          <Grid container>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                padding: 2,
              }}
              xs={12}
            >
              <Divider sx={{ width: '100%' }} />
            </Grid>
            {/* Manejo */}
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              xs={12}
            >
              <Typography fontSize={14} fontWeight={600}>
                Para cargar loteos y datos históricos
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                paddingBottom: 2,
              }}
              xs={12}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: '#277fca',
                }}
                onClick={() => {
                  navigate('/management')
                }}
              >
                {'Continuar con Manejo >'}
              </Typography>
            </Grid>
            {/* Monitoreo */}
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              xs={12}
            >
              <Typography fontSize={14} fontWeight={600}>
                Para visualizar o cargar datos para el trabajo a campo
              </Typography>
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
              }}
              xs={12}
            >
              <Typography
                fontSize={14}
                fontWeight={600}
                style={{
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  color: '#277fca',
                }}
                onClick={() => {
                  navigate('/monitoring')
                }}
              >
                {'Continuar con Monitoreo >'}
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        {saved ? (
          <Grid container>
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                padding: 2,
              }}
              xs={12}
            >
              <Divider sx={{ width: '100%' }} />
            </Grid>
            <Grid
              item
              sx={{
                display: 'flex',
                paddingX: 2,
              }}
              xs={12}
            >
              <Typography fontSize={16} fontWeight={600}>
                Exportar
              </Typography>
            </Grid>
            <Grid
              item
              md={8}
              sx={{
                display: 'flex',
                alignItems: 'center',
                alignContent: 'center',
                padding: 2,
              }}
              xs={12}
            >
              <FormControl>
                <FormLabel>Formato</FormLabel>
                <RadioGroup row value={fileType} onChange={handleFileType}>
                  <FormControlLabel control={<Radio />} label="KML" value="kml" />
                  <FormControlLabel control={<Radio />} label="GeoJSON" value="geojson" />
                </RadioGroup>
              </FormControl>
              {[userRoles.DataScientist, userRoles.Admin].includes(userRoleSelected) && (
                <FormControl>
                  <FormLabel>Incluir</FormLabel>
                  <FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <Checkbox
                      checked={includeSoilInfo}
                      name="includeSoilInfo"
                      onChange={handleIncludeSoilInfo}
                    />
                    <FormLabel>Información de suelo</FormLabel>
                  </FormControl>
                  <FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                    <Checkbox
                      checked={includeProjectArea}
                      name="includeProjectArea"
                      onChange={handleIncludeProjectArea}
                    />
                    <FormLabel>Área de proyecto</FormLabel>
                  </FormControl>
                </FormControl>
              )}
            </Grid>
            <Grid
              item
              md={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                alignContent: 'center',
                padding: 2,
              }}
              xs={12}
            >
              <Button
                color="primary"
                disabled={!fileType}
                variant="contained"
                onClick={handleDownload}
              >
                Descargar
              </Button>
            </Grid>
          </Grid>
        ) : null}
      </Box>

      {!saved && etapa !== 'showingFoldersManual' ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            padding: '10px',
            background: '#eaf4f6',
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            width: (window.innerWidth / 3) * 1,
            zIndex: 900,
          }}
        >
          <Tooltip placement="top-end" title="Seleccionar un perímetro para poder guardar">
            <InfoOutlinedIcon />
          </Tooltip>
          <Button
            disabled={!featuresAreValid}
            variant="contained"
            onClick={handleCheckForRequiredFields}
          >
            Guardar
          </Button>
          <Button value="archivo" onClick={handleCancelShowFolders}>
            Cancelar
          </Button>
          <CategorizationConfirm
            buttonValue="showingFoldersArchivo"
            handleClose={handleClose}
            handleConfirm={handleSaveCategorization}
            open={modalConfirmationOpen}
          />
        </Box>
      ) : null}
    </Box>
  )
}

const LayerPanel = ({
  title,
  color,
  elements,
  validation,
  saved,
  expandedPanels,
  categoryPanel,
  handleChange,
  handleLayerTypeChange,
  perimeter,
  handleName,
  handleAddDependencies,
  handleRemoveFeature,
  handleRemoveDependencies,
  handleOtherName,
  handleHasGrazingManagement,
  handleExclusionAreaTypeChange,
  handleFeatureChange,
  handleMapUpdate,
  children,
}) => {
  const handleSelectFeature = newFeature => {
    const newFeatures = elements.map(feature => {
      if (feature.properties.id === newFeature.properties.id) {
        feature.properties.selected = !feature.properties.selected
      }
      return feature
    })

    handleFeatureChange(newFeatures)
    handleMapUpdate()
  }

  return (
    <>
      {elements?.length > 0 && (
        <Accordion
          expanded={expandedPanels[categoryPanel.panelName] || validation !== null}
          onChange={handleChange(categoryPanel)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id={`${categoryPanel.type}-header`}
            sx={{
              '& .MuiAccordionSummary-content': {
                margin: 0,
              },
            }}
          >
            <AccordionHeader
              color={color}
              elements={elements}
              title={title}
              warning={validation?.message}
            />
          </AccordionSummary>
          <AccordionDetails sx={{ p: 1 }}>
            {validation?.message && (
              <Alert severity="error" variant="filled">
                <Typography
                  sx={{
                    fontSize: 16,
                    fontWeight: 600,
                  }}
                >
                  {validation.message}
                </Typography>
              </Alert>
            )}
            {children}
            {elements.map((area, index) => {
              return (
                <Fragment key={index}>
                  <AreaRow
                    error={validation?.errorsIndexes?.includes(index)}
                    feature={area}
                    handleAddDependencies={handleAddDependencies}
                    handleExclusionAreaTypeChange={handleExclusionAreaTypeChange}
                    handleFeature={handleLayerTypeChange}
                    handleHasGrazingManagement={handleHasGrazingManagement}
                    handleName={handleName}
                    handleOtherName={handleOtherName}
                    handleRemoveDependencies={handleRemoveDependencies}
                    handleRemoveFeature={handleRemoveFeature}
                    handleSelectFeature={handleSelectFeature}
                    perimeter={perimeter}
                    saved={saved}
                    type={categoryPanel.type}
                  />
                  {index + 1 < elements.length && <Divider />}
                </Fragment>
              )
            })}
          </AccordionDetails>
        </Accordion>
      )}
    </>
  )
}

export default ShowFolders
